import React from 'react'

import "./Wishlist.css"

const Wishlist = () => {
  return (
    <div>Wishlist</div>
  )
}

export default Wishlist