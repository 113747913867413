import React from 'react'

import "./Header.css"

const Header = () => {
  return (
    <header className='header-container'>
        <h1 className='header-title'>Kiril's Movie Site</h1>
    </header>
  )
}

export default Header