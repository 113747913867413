import React from 'react'

import "./Contact.css"

const Contact = () => {
  return (
    <div>Contact</div>
  )
}

export default Contact